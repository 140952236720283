import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
const Moon = dynamic(() => import('../app/_icons/Moon'), { ssr: false })
const Sun = dynamic(() => import('../app/_icons/Sun'), { ssr: false })

function DarkMode() {
  // State to track whether dark mode is active
  const [darkMode, setDarkMode] = useState(false)

  // Function to toggle dark mode and save preference to local storage
  const toggleDarkMode = () => {
    // Toggle dark mode state
    setDarkMode((prevDarkMode) => !prevDarkMode)

    // Save dark mode preference to local storage
    localStorage.setItem('darkMode', JSON.stringify(!darkMode))
  }

  // Check local storage for dark mode preference on page load
  useEffect(() => {
    const userPreference = JSON.parse(localStorage.getItem('darkMode'))
    if (userPreference !== null) {
      setDarkMode(userPreference)
    } else {
      // Use system preference if user preference is not available
      const systemPreference = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches
      setDarkMode(systemPreference)
    }
  }, [])

  // Apply dark mode class to the HTML element
  useEffect(() => {
    const htmlElement = document.documentElement
    if (darkMode) {
      htmlElement.classList.add('dark')
    } else {
      htmlElement.classList.remove('dark')
    }
  }, [darkMode])

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className='flex ml-2  w-fit absolute'
    >
      {/* Render Moon icon if dark mode is active, otherwise render Sun icon */}
      <button
        onClick={toggleDarkMode}
        className='border-2 border-white dark:border-black rounded-md bg-textColor dark:bg-bgColor h-6 w-6 shadow-custom'
      >
        {darkMode ? <Moon /> : <Sun />}
      </button>
    </form>
  )
}

export default DarkMode
