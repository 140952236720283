'use client'
import { useState, useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Image from 'next/image'
import Link from 'next/link'
import DarkMode from './DarkMode'
// import DarkMode from './DarkMode'

const navigation = [
  // { name: 'Våra tjänster', href: '/produkt' },
  { name: 'galleri', href: '/galleri', title: 'Galleri' },
  { name: 'platsbyggd', href: '/platsbyggd', title: 'Platsbyggd' },
  { name: 'tjänster', href: '/tj%C3%A4nster', title: 'Tjänster' },
  { name: 'om oss', href: '/om-oss', title: 'Om oss' },
  { name: 'kontakt', href: '/kontakt', title: 'Kontakt' },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [isTransparent, setIsTransparent] = useState(false)

  useEffect(() => {
    let timeout

    const handleScroll = () => {
      setIsTransparent(true)

      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setIsTransparent(false)
      }, 500)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      clearTimeout(timeout)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <header className='fixed inset-x-0 top-0 z-50 '>
      <nav
        className={`p-6 lg:px-8 capitalize transition-opacity duration-500 backdrop-blur-md lg:flex lg justify-around  ${
          isTransparent ? 'opacity-0' : 'opacity-100'
        }`}
      >
        <div className='flex justify-between items-center'>
          <Link href={'/'} title='Fransonssnickeri'>
            <span className='sr-only'>Your Company</span>
            <Image
              className={`h-16 w-auto relative ${
                mobileMenuOpen ? 'opacity-0' : 'opacity-100'
              }`}
              src={'/logo.svg'}
              alt='logo'
              title='Fransonssnickeri'
              width={30}
              height={30}
              onClick={() => setMobileMenuOpen(false)}
            />
          </Link>
          <div className='flex lg:hidden'>
            {!mobileMenuOpen && (
              <button
                type='button'
                className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 '
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className='sr-only'>Open main menu</span>
                <Bars3Icon
                  className='h-12 w-12 transform hover:text-gray-500 transition-transform duration-1500 text-textColor dark:text-bgColor '
                  aria-hidden='true'
                />
              </button>
            )}
          </div>
        </div>

        <div className='hidden items-center justify-evenly md:hidden lg:flex lg:gap-x-12 w-9/12'>
          {navigation.map((item) => (
            <Link
              key={item.name}
              href={item.href}
              title={item.title}
              className='text-l font-semibold leading-6 text-textColor dark:text-bgColor hover:text-slate-500 dark:hover:text-slate-500 '
            >
              {item.name}
            </Link>
          ))}
        </div>
      </nav>

      <Dialog
        as='div'
        className='lg:hidden'
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className='fixed inset-0 z-50' />
        <Dialog.Panel
          className='fixed inset-y-0 right-0 z-50 w-full overflow-y-auto sm:ring-1 sm:ring-gray-900/10'
          enter='transition-transform ease-in-out duration-300'
          enterfrom='translate-x-full'
          enterto='translate-x-0'
          leave='transition-transform ease-in-out duration-300'
          leavefrom='translate-x-0'
          leaveto='translate-x-full'
        >
          <div className='flex justify-between p-6 lg:px-8 backdrop-blur '>
            <Link href={'/'} title='Fransonssnickeri'>
              <span className='sr-only'>Your Company</span>
              <Image
                className='h-16 w-auto'
                src={'/logo.svg'}
                alt='logo'
                title='Fransonssnickeri'
                width={30}
                height={30}
                onClick={() => setMobileMenuOpen(false)}
              />
            </Link>
            <div className='flex'>
              <button
                className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-textColor dark:text-bgColor transform hover:rotate-90 transition-transform duration-300'
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className='sr-only'>Close menu</span>
                <XMarkIcon className='h-12 w-12' aria-hidden='true' />
              </button>
            </div>
          </div>
          <div className="flow-root px-6 py-6 bg-[url('/images/askträ.webp')] bg-no-repeat  bg-cover bg-center h-full">
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6'>
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    title={item.title}
                    className='-mx-3 text-xl block rounded-lg px-3 py-2 font-semibold leading-7 dark:text-textColor hover:text-black hover:bg-orange-100 hover:shadow-md cursor-pointer'
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      <DarkMode />
    </header>
  )
}
